import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { NoIndex, description, title } from '@components/layout/SEO';
import { ResetURLSent } from '@features/reset-password/ResetURLSent';

const ResetURLSentPage: FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>パスワードを忘れた方はこちら（送信完了） | {title}</title>
        <meta name="description" content={description} />
        {NoIndex}
      </Helmet>
      <ResetURLSent />
    </Layout>
  );
};

export default ResetURLSentPage;
